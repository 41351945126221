import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, switchMap } from 'rxjs';
import { SettingsService } from '@services/currency/settings.service';
import { environment } from '@env';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class CurrencyService {
  public currencies: any[] = [
    { name: 'USD', code: 'USD', exchange: 1, symbol: '$', baseCurrency: true },
    { name: 'EUR', code: 'EUR', exchange: 1, symbol: '€', baseCurrency: false },
    { name: 'CAD', code: 'CAD', exchange: 1, symbol: '€', baseCurrency: false },
    { name: 'GBP', code: 'GBP', exchange: 1, symbol: '€', baseCurrency: false },
    { name: 'CUP', code: 'CUP', exchange: 1, symbol: '$', baseCurrency: false },
    { name: 'RUB', code: 'RUB', exchange: 1, symbol: '₽', baseCurrency: false }
  ];
  $currencyUpdated: Subject<any> = new Subject<any>();
  $currencyConfig: BehaviorSubject<any> = new BehaviorSubject(null);
  currency: any;
  configuration: any;
  private agency: string = environment.agency;
  url = environment.apiUrl + 'config';

  constructor(
    private settingsService: SettingsService,
    private httpClient:HttpClient
  ) {
    this.getCurrenciesConfiguration();

    if (this.agency === 'daiquiri') {
      this.currencies = [
        { name: 'USD', code: 'USD', exchange: 1, symbol: '$', baseCurrency: true },
        { name: 'EUR', code: 'EUR', exchange: 1, symbol: '€', baseCurrency: false }
      ];
    }
  }

  public setCurrency(currency: any): void {
    this.currency = currency;
    this.$currencyUpdated.next(this.currency);
  }

  public getCurrency(): any {
    return this.currency;
  }

  public getCurrencies(): any[] {
    return this.currencies;
  }

  private getCurrenciesConfiguration(): void {
    this.settingsService
      .getValue('currencies')
      .then((data) => {
        this.configuration = data;
        this.currencies.forEach((c: any) => {
          c.exchange = this.configuration[c?.code];
          c.baseCurrency = this.configuration.baseCurrency === c?.code;
          return c;
        });
        this.currency = this.currencies.filter((c: any) => c.baseCurrency)[0];
        this.$currencyUpdated.next(this.currency);
      });
  }

  public updateCurrencyConfig():Observable<void>{
    const httpParams = new HttpParams().append('filter[$and][llave]', 'currencies');
    return this.httpClient.get<any>(this.url, {params: httpParams}).pipe(
      switchMap(response=>{
        this.$currencyConfig.next(response.data[0].valor);
        return of();
      })
    );
  }
}
