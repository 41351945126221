import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env';

@Pipe({
  name: 'currencyExchange'
})
export class CurrencyExchangePipe implements PipeTransform {

  defaultCurrency = environment.defaultCurrency;

  transform(value: unknown, exchangeData: any, currency?: string): string {
    if (!exchangeData)
      return `${value}`;
    if (currency && exchangeData?.[this.defaultCurrency])
      return `${(value as number) * (exchangeData?.[currency] ?? 1)}`;
    return `${(value as number) * (exchangeData?.[this.defaultCurrency] ?? 1)}`;
  }

}
