import { ShowToastrService } from './../show-toastr/show-toastr.service';
import { LoggedInUserService } from '@services/loggedInUser/logged-in-user.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '../utils/utils.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
  url = '';

  constructor(
    private utilsService: UtilsService,
    private showToastr: ShowToastrService,
    private loggedInUserService: LoggedInUserService,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        this.url = event.url;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error del lado del cliente: ${error.error.message}`;
          this.showToastr.showInfo(errorMessage, 'Error');
        } else {
          this.processingBackendError(error);
        }
        return throwError(error);
      })
    );
  }

  processingBackendError(err: any) {
    if (err.status === 401) {
      if (this.router.url.includes('my-account')) {
        // que debe hacer en este caso
      } else {
        localStorage.removeItem('user');
        this.loggedInUserService.setLoggedInUser(null);
        this.loggedInUserService.$loggedInUserUpdated.next(null);
        this.utilsService.errorHandle(err);
        this.router.navigate(['']).then();
      }
    } else if (err.status === 403 || err.status === 404 || err.status === 400 || err.status === 500) {
      this.utilsService.errorHandle(err);
    } else if (err.status === 409) {
      if (err?.error?.errors[0]?.field) {
        this.showToastr.showWarning(err?.error?.errors[0]?.field[0]?.title, 'Error de sincronización');
      } else {
        this.showToastr.showWarning(err?.error?.errors[0]?.code, 'Error de sincronización');
      }
    } else if (err.status === 0 || err.status === undefined) {
      this.utilsService.errorHandle(err);
    }
  }
}
