import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class SettingsService {
  url = environment.apiUrl + 'config';
  urlConfigBy = environment.apiUrl + 'config-by';

  constructor(private httpClient: HttpClient) {
  }

  // eslint-disable-next-line require-await
  public async getValue(key): Promise<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('filter[$and][llave]', key);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient
          .get<any>(this.url, { params: httpParams })
          .toPromise();
        const data = response?.data[0]?.valor ? response.data[0].valor : null;
        if (data) {
          resolve(data.constructor === 'string' ? JSON.parse(data) : data);
        } else {
          resolve(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  // eslint-disable-next-line require-await
  public async setValue(key, value): Promise<any> {
    // value = value.constructor === String ? value : JSON.stringify(value);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient
          .post<any>(this.url,
            {
              llave: key,
              valor: value
            })
          .toPromise();
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  // eslint-disable-next-line require-await
  public async deleteValue(key): Promise<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('filter[$and][llave]', key);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpClient
          .delete<any>(this.url, { params: httpParams })
          .toPromise();
      } catch (error) {
        reject(error);
      }
    });
  }

  // eslint-disable-next-line require-await
  public async getConfigByValue(key): Promise<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('filter[$and][llave]', key);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient
          .get<any>(this.urlConfigBy, { params: httpParams })
          .toPromise();
        const data = response && response.data.length && response.data[0].valor ? response.data[0].valor : null;
        if (data) {
          resolve(data.constructor === 'string' ? JSON.parse(data) : data);
        } else {
          resolve(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  // eslint-disable-next-line require-await
  public async setConfigByValue(key, value): Promise<any> {
    // value = value.constructor === String ? value : JSON.stringify(value);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient
          .post<any>(this.urlConfigBy,
            {
              llave: key,
              valor: value
            })
          .toPromise();
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  // eslint-disable-next-line require-await
  public async deleteConfigByValue(key): Promise<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('filter[$and][llave]', key);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
       await this.httpClient
          .delete<any>(this.urlConfigBy, { params: httpParams })
          .toPromise();
      } catch (error) {
        reject(error);
      }
    });
  }
}
