import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';
import { environment } from '@env';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  token: any = null;
  currency: any = null;
  language: any = null;
  version: any = null;
  constructor(private loggedInUserService: LoggedInUserService) {
    this.token = loggedInUserService.getTokenOfUser();
    this.version = environment.versions.app;
    this.currency = JSON.parse(localStorage.getItem('currency')) ? JSON.parse(localStorage.getItem('currency')).name : null;
    this.language = JSON.parse(localStorage.getItem('language')) ? JSON.parse(localStorage.getItem('language')).lang : null;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.loggedInUserService.getTokenOfUser() || this.loggedInUserService.getToken();
    this.currency = JSON.parse(localStorage.getItem('currency')) ? JSON.parse(localStorage.getItem('currency')).name : null;
    this.language = JSON.parse(localStorage.getItem('language')) ? JSON.parse(localStorage.getItem('language')).lang : null;
    if (this.token && !request.url.includes('login') && !request.url.includes('agency-by-mail'))
      request = request.clone({
        setHeaders: {
          Authorization: this.token
        }
      });

    if (this.language)
      request = request.clone({
        setHeaders: {
          language: this.language
        }
      });

    // request = request.clone({
    //   setHeaders: {
    //     "AgencyToken": environment.AgencyToken.toString(),
    //   },
    // });

    if (this.version) {
      request = request.clone({
        setHeaders: {
          version: this.version
        }
      });
    }
    if (this.currency)
      request = request.clone({
        setHeaders: {
          currency: this.currency
        }
      });

    return next.handle(request);
  }
}
