import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptorService } from '@services/interceptors/http-error-interceptor.service';
import { TokenInterceptorService } from '@services/interceptors/token-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@services/translate-factory/translate-loader';
import { StoreModule } from '@ngrx/store';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { EffectsModule } from '@ngrx/effects';
import { MatNativeDateModule } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env';
import { UpdateControllerModule } from '@services/updates-controller/update-controller.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MyMatPaginatorIntl } from '@core/classes/my-mat-pagination-class';
import { CurrencyService } from '@core/services/currency/currency.service';
import { SettingsService } from '@core/services/currency/settings.service';
import { CurrencyExchangePipe } from '@core/pipes/currency-exchange/currency-exchange.pipe';
import { CurrencyExchangeModule } from '@core/pipes/currency-exchange/currency-exchange.module';

registerLocaleData(localeEs, 'es');

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    UpdateControllerModule,
    CurrencyExchangeModule
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new MyMatPaginatorIntl(translateService)
    },
    CurrencyService,
    SettingsService,
    CurrencyExchangePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
