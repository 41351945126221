import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitlePageService {
  public SERVICE_BUSSINESS = environment.ServiceBusiness;
  private strSectionBase = 'Inicio'; // Key Base
  private strServiceBase = environment.ServiceBusiness; // Key Base
  private strSection: string; // Key set custom
  private strService: string; // Key set custom
  private titleString: string; // String to set title

  constructor(
    private titleService: Title,
    private translate: TranslateService
  ) {
  }

  /**
   * Set title on HTML Page, with translate
   *
   * @param strSectionValue key(from the translation) of the section to set
   * @param strServiceValue key(from the translation) of the service to set
   */
  setTitle(strSectionValue?: string, strServiceValue?: string) {
    if (strSectionValue) {
      this.strSection = strSectionValue;
    } else if (!strSectionValue && !this.strSection) {
      this.strSection = this.strSectionBase;
    }

    if (strServiceValue) {
      this.strService = strServiceValue;
    } else if (!strServiceValue && !this.strService) {
      this.strService = this.strServiceBase;
    }

    setTimeout(() => {
      this.titleString
        = this.translate.instant(this.strSection)
        + ' | '
        + this.translate.instant(this.strService)
        + ', '
        + environment.agencyName;

      this.titleService.setTitle(this.titleString);
    }, 50);
  }
}
