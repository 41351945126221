import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { marker as _t } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreenService } from '@services/splash-screen.service';
import { Theme } from '@services/theme-configuration.service';
import { TitlePageService } from '@services/title-page/title-page.service';
import Cookies from 'js-cookie';
import { NgxSpinnerService } from 'ngx-spinner';
import { EncryptDecryptService } from '@services/encrypt-decrypt.service';
import { Subject, takeUntil } from 'rxjs';
import { LoggedInUserService } from '@services/loggedInUser/logged-in-user.service';
import { ShowToastrService } from '@services/show-toastr/show-toastr.service';
import { SocketIoClientService } from '@services/socket-io-client/socket-io-client.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from "lodash";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  pageTitle: string = _t('Inicio');
  private unsubscribeAll: Subject<any> = new Subject<any>();
  arrayThemes: Theme[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private router: Router,
    private socketIoClient: SocketIoClientService,
    private showToastr: ShowToastrService,
    private loggedInUserService: LoggedInUserService,
    private splashScreenService: SplashScreenService,
    private titlePageService: TitlePageService,
    private encryptDecryptService: EncryptDecryptService
  ) {
    this.renamePage();
    this.spinner.show().then();

    let language = environment.defaultLanguage;
    if ('language' in localStorage || Cookies.get('language')) {
      language = JSON.parse(localStorage.getItem('language'));
      if (!language) {
        language = JSON.parse(Cookies.get('language'));
      }
      language = language && (language?.lang === 'es' || language?.lang === 'en' || language?.lang === 'ru' || language?.lang === 'it') ? language : {
        name: 'Español',
        image: 'assets/images/flags/es.png',
        lang: 'es'
      };
      localStorage.setItem('language', JSON.stringify(language));
      Cookies.set('language', JSON.stringify(language), { sameSite: 'Lax', secure: true });
      this.translate.setDefaultLang(language?.lang);
      this.translate.use(language?.lang);
    } else {
      this.translate.setDefaultLang(language?.lang);
      this.translate.use(language?.lang);
      localStorage.setItem('language', JSON.stringify(language));
      Cookies.set('language', JSON.stringify(language), { sameSite: 'Lax', secure: true });
    }

    const isCookieAccount = Cookies.get('account');

    if (!isCookieAccount) {
      this.router.navigate(['authentication/login']).then();
    } else {
      this.loggedInUserService.setToken(
        this.encryptDecryptService.decrypt(Cookies.get('account'))
      );
      this.loggedInUserService
        .getProfile()
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((data) => {
          if (data && data.data) {
            const user: any = {
              profile: {},
              token: ''
            };
            user.profile = this.loggedInUserService.processPermission(data?.data);
            user.token = this.encryptDecryptService.decrypt(Cookies.get('account'));
            this.proceedToLogin(user);
          }
        });
    }
  }

  private proceedToLogin(user: any): void {
    this.socketIoClient._initSocket(user?.token);
    this.loggedInUserService.updateUserProfile(user);
    this.showToastr.showSucces(_t('Usted está logueado en nuestro sistema.'), _t('Felicidades!'), 5000);
    this.router.navigate(['backend/search']).then();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinner.hide().then();
    }, 100);
  }

  private renamePage(): void {
    this.titlePageService.setTitle(
      this.pageTitle,
      this.titlePageService.SERVICE_BUSSINESS
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(true);
    this.unsubscribeAll.complete();
  }
}
