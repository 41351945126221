/* eslint-disable */
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { LoggedInUserService } from "../loggedInUser/logged-in-user.service";

@Injectable({
  providedIn: "root",
})
export class SocketIoClientService implements OnDestroy {
  public url = environment.apiUrl + "connect";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  loggedInUser: any;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  _unsubscribeAll: Subject<any>;
  // @ts-ignore
  private socket: SocketIOClient.Socket;
  // @ts-ignore
  private socketSearch: SocketIOClient.Socket;

  // $socketioRefrescated: Subject<any> = new Subject<any>();

  constructor(private loggedInUserService: LoggedInUserService) {
    this._unsubscribeAll = new Subject<any>();

    this.loggedInUser = this.loggedInUserService.getLoggedInUser();

    if (this.loggedInUser) {
      this._initSocket(this.loggedInUserService.getTokenOfUser());
    }

    this.loggedInUserService.$loggedInUserUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.loggedInUser = this.loggedInUserService.getLoggedInUser();
        if (this.loggedInUser) {
          this._initSocket(this.loggedInUserService.getTokenOfUser());
        }
      });
  }

  // OFF
  off(eventName) {
    this.socket.off(eventName);
  }

  // HANDLER
  listen(eventName): Observable<any> {
    return Observable.create(observer => {
      this.socket.on(eventName, (data) => {
        observer.next(data);
      });
    });
  }

  public _initSocket(token) {

    if (!this.socket) {
      // @ts-ignore
      this.socket = io(this.url, {
        query: {
          Authorization: token,
        },
      }).connect();

      this.listen("hola")
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          console.log("******socket on*******");
        });
    } else {
      this.disconnect();
      this._initSocket(token);
    }
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public disconnectSearchSocket() {
    if (this.socketSearch) {
      this.socketSearch.disconnect();
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.disconnect();
    this.disconnectSearchSocket();
  }
}
