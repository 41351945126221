/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  UrlSegment,
  Router,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoggedInUserService } from '@services/loggedInUser/logged-in-user.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ShowToastrService } from '@services/show-toastr/show-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class BackendGuard implements CanActivate, CanLoad {
  constructor(
    private loggedInUserService: LoggedInUserService,
    private route: Router,
    private authService: AuthenticationService,
    private router: Router,
    private showToastr: ShowToastrService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const data = next?.data;
    const user = this.loggedInUserService.getLoggedInUser();

    if (!data?.permissionName) {
      return true;
    }
    if (user?.role === 'admin' || user?.role === 'Admin') {
      return true;
    }

    if (user?.Permissions) {
      if (Object.keys(user?.Permissions).length > 0) {
        if (data?.permissionName && data?.strict && user?.Permissions[data?.permissionName]) {
          return true;
        } else if (user && (data?.permissionName && user['canList' + data?.permissionName])) {
          return true;
        }
      } else {
        this.notPrivileges();
      }
    } else {
      this.notPrivileges();
    }

    this.route.navigate(['/error/acceso-prohibido']).then();
    return false;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loggedInUserService.getLoggedInUser()) {
      return true;
    } else {
      this.route.navigate(['/error/acceso-prohibido']).then();
      return false;
    }
  }

  private notPrivileges(): void {
    this.authService.logout()
      .subscribe(() => {
        localStorage.removeItem('user');
        this.router.navigate(['']).then();
        this.showToastr.showInfo('Usted no tiene privilegios suficientes', 'Información', 5000);
      });
  }
}
