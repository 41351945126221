import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthenticationGuard } from './pages/authentication/authentication.guard';
import { BackendGuard } from './components/backend/backend.guard';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'authentication',
        loadChildren: () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'backend',
        loadChildren: () => import('./components/backend/backend.module').then((m) => m.BackendModule),
        canActivate: [BackendGuard]
      },
      {
        path: 'create-event',
        loadChildren: () => import('./components/backend/backOffice/create-event/create-event.module').then((m) => m.CreateEventModule),
        canActivate: [BackendGuard]
      },
      {
        path: 'error',
        loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
