/* eslint-disable dot-notation */
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class MyMatPaginatorIntl extends MatPaginatorIntl {
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService
  ) {
    super();

    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translateService
      .get([
        'ITEMS_PER_PAGE',
        'NEXT_PAGE',
        'PREVIOUS_PAGE',
        'FIRST_PAGE_LABEL',
        'LAST_PAGE_LABEL'
      ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['NEXT_PAGE'];
        this.previousPageLabel = translation['PREVIOUS_PAGE'];
        this.firstPageLabel = translation['FIRST_PAGE_LABEL'];
        this.lastPageLabel = translation['LAST_PAGE_LABEL'];
        // this.OF_LABEL = translation['PAGINATOR.OF_LABEL'];
        this.changes.next();
      });
  }

  getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant('RANGE_PAGE_LABEL_1', { length });
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translateService.instant('RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
  };
}
