import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyExchangePipe } from './currency-exchange.pipe';
import { CurrencyChangeToUsdPipe } from './currency-exchange-to-usd.pipe';


@NgModule({
  declarations: [
    CurrencyExchangePipe,
    CurrencyChangeToUsdPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CurrencyExchangePipe,
    CurrencyChangeToUsdPipe
  ]
})
export class CurrencyExchangeModule { }
