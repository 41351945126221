import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { LoggedInUserService } from '@services/loggedInUser/logged-in-user.service';
import { Observable } from 'rxjs';
import { IUser } from '../../classes/user.class';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  userUrl = environment.securityUrl + 'auth/login';
  userLogout = environment.securityUrl + 'auth/logout';
  urlSingUp = environment.securityUrl + 'auth/sign-up';
  urlSendPing = environment.securityUrl + 'auth/validate';
  urlForgot = environment.securityUrl + 'auth/forgot';
  urlChangePass = environment.securityUrl + 'auth/change-pass';
  urlProfile = environment.apiUrl + 'profile';
  private loggedInUser: any;

  constructor(
    private httpClient: HttpClient,
    private loggedInUserService: LoggedInUserService
  ) {
    this.loggedInUser = loggedInUserService.getLoggedInUser();
  }

  login(user: string, password: string, agency?: string) {
    const base64EncodedPw = btoa(user + ':' + password);
    const httpOptions = agency ? {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AgencyCode': agency,
        Authorization: 'Basic ' + base64EncodedPw
      }),
      username: user,
      password: password
    } : {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64EncodedPw
      }),
      username: user,
      password: password
    };

    return this.httpClient.get<IUser>(this.userUrl, httpOptions);
  }
  changeAgency(token: string, agency?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AgencyCode': agency,
        Authorization: token
      })
    };

    return this.httpClient.get<IUser>(this.userUrl, httpOptions);
  }

  logout(): Observable<any> {
    const httpOptions = {};
    return this.httpClient.post<any>(
      this.userLogout,
      { Authorization: this.loggedInUser?.token },
      httpOptions
    );
  }

  singUp(body: any): Observable<any> {
    return this.httpClient.post<any>(this.urlSingUp, body);
  }

  passForgot(body: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', body.email);
    return this.httpClient.get<any>(this.urlForgot, { params: httpParams });
  }

  changePass(body: any): Observable<any> {
    return this.httpClient.post<any>(this.urlSendPing, body);
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  getProfile(): Observable<any> {
    return this.httpClient.get(this.urlProfile);
  }

  editProfile(data: any): Observable<any> {
    return this.httpClient.patch(this.urlProfile, data);
  }
}
